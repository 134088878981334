import React from 'react';
import ReactDOM from 'react-dom';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import App from './App';

const RECAPTCHA_KEY = process && process.env ? process.env.REACT_APP_CAPTCHA_KEY : '';


ReactDOM.render(
  <GoogleReCaptchaProvider
    reCaptchaKey={RECAPTCHA_KEY}
    language="en"
    scriptProps={{ appendTo: 'head' }}
  ><App /></GoogleReCaptchaProvider>,
  document.getElementById('root')
);
