const axios = require('axios');
const endpoint = process.env.REACT_APP_API_URL ? process.env.REACT_APP_API_URL : '';

const postSubmission = (formData) => {
    return axios({
        method: 'post',
        url: `${endpoint}app/v1/new_user.php`,
        data: formData,
    }).then(function (response) {
        return response.data
    }).catch(function (error) {
        return {status: "error", description: error.response.data}
    });
}

export {
    postSubmission
};
