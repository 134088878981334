import React, { useState } from "react";
import { Helmet } from "react-helmet";
import { Button, Col, Container, Row } from "react-bootstrap";
import { useForm, Controller } from "react-hook-form";
import { GoogleReCaptcha } from "react-google-recaptcha-v3";
import InputMask from "react-input-mask";
import _ from "lodash";

//Our Components
import ModalSuccessMessage from "../components/molecules/ModalSuccessMessage/index";
import Layout from "../components/templates/Layout";

//Connections
import { postSubmission } from "../services/connections";

const defaultValues = {
	first_name: "",
	last_name: "",
	email: "",
	title: "",
	bta: "",
	phone: "",
};

const RECAPTCHA_KEY = process && process.env ? process.env.REACT_APP_CAPTCHA_KEY : '';


const Main = () => {
	const {
		register,
		control,
		handleSubmit,
		formState: { errors },
		setValue,
	} = useForm({ defaultValues });
	
	const [loading, setLoading] = useState(false);
	const [generalError, setGeneralError] = useState('');
	const [showModalSuccess, setShowModalSuccess] = useState(false);
	
	
	const resetForm = () => {
		setValue("first_name", "", { shouldValidate: false });
		setValue("last_name", "", { shouldValidate: false });
		setValue("email", "", { shouldValidate: false });
		setValue("title", "", { shouldValidate: false });
		setValue("bta", "", { shouldValidate: false });
		setValue("phone", "", { shouldValidate: false });
	};
	
	const preventSubmit = async (e) => {
		e.preventDefault();
		return await (handleSubmit(onSubmit))(e);
	};

	const onSubmit = async (formData) => {
		
		if (loading) {
			return false;
		}
		setLoading(true);

		const mapFormData = new FormData();
		
		mapFormData.append('name', formData.first_name);
		mapFormData.append('last', formData.last_name);
		mapFormData.append('email_address', formData.email);
		mapFormData.append('title_name', formData.title);
		mapFormData.append('bta_value', formData.bta);
		mapFormData.append('phone_number', formData.phone);
		if(RECAPTCHA_KEY) {
			mapFormData.append('robot', formData.captcha);
		}
		
		try {
			const response = await postSubmission(mapFormData);
			
			if(response.status_response === 'error') {
				
				if(response.message === 'NO-ERROR-MESSAGE') {
					setGeneralError('Something went wrong. Try again later.');
				} else {
					setGeneralError(response.message);
				}
				setTimeout(() => {
					setGeneralError('');
					setLoading(false);
				}, 5000);
			} else {
				setShowModalSuccess(true);
				resetForm();
				setLoading(false);
			}
		} catch (error) {
			setLoading(false);
		}
		return false;
	};
	
	const onCloseModal = () => {
		setShowModalSuccess(false);
	};
	

	return (
		<Layout modalOpened={showModalSuccess}>
			{
				showModalSuccess && <ModalSuccessMessage onClose={onCloseModal} /> 
			}
			<section>
				<Helmet>
					<title>PROPAC + FRITO-LAY</title>
				</Helmet>
				<Container className="main-content">
					<div>
						<div className="subtitles">
							<Row className="">
								<Col>
									<div className="py-4">
										<h6 className="blue-heading">winning together every day</h6>
									</div>
									<div className="py-4">
										<h6 className="white-heading">enter for a chance to win $5,000 in agency hours</h6>
									</div>
								</Col>
							</Row>
						</div>
						<form className="drawer-form" onSubmit={preventSubmit}>
							<label className="label-required mb-4">*REQUIRED FIELDS</label>
							<div className="wrap-fields">
								<Row>
									<Col xs="12" md="6">
										<div className="form-group">
											<label>FIRST NAME*</label>
											<input
												type="text"
												className="form-control"
												placeholder="Enter Your First Name"
												{...register("first_name", {
													required: {
														message: "Required.",
														value: true,
													},
												})}
											/>
											<p className="text-danger mt-1">{_.get(errors,"first_name.message")}</p>
										</div>
									</Col>
									<Col xs="12" md="6">
										<div className="form-group">
											<label>LAST NAME*</label>
											<input
												type="text"
												className="form-control"
												placeholder="Enter Your Last Name"
												{...register("last_name", {
													required: {
														message: "Required.",
														value: true,
													},
												})}
											/>
											<p className="text-danger mt-1">{_.get(errors,"last_name.message")}</p>
										</div>
									</Col>
								</Row>
								<Row className="mt-2">
									<Col xs="12" md="6">
										<div className="form-group">
											<label>EMAIL ADDRESS*</label>
											<input
												type="email"
												className="form-control"
												placeholder="Enter Your Email Address"
												{...register("email", {
													required: {
														message: "Required.",
														value: true,
													},
													pattern: {
														message: "Email format invalid.",
														value: /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/,
													},
												})}
											/>
											<p className="text-danger mt-1">{_.get(errors,"email.message")}</p>
										</div>
									</Col>
									<Col xs="12" md="6">
										<div className="form-group">
											<label>BRAND / TEAM / ACCOUNT*</label>
											<input
												type="text"
												className="form-control"
												placeholder="Enter Your Brand / Team / Account"
												{...register("bta", {
													required: {
														message: "Required.",
														value: true,
													},
												})}
											/>
											<p className="text-danger mt-1">{_.get(errors,"bta.message")}</p>
										</div>
									</Col>
								</Row>
								<Row className="mt-2">
									<Col xs="12" md="12">
										<div className="form-group">
											<label>TITLE*</label>
											<input
												type="text"
												className="form-control"
												placeholder="Enter Your Title"
												{...register("title", {
													required: {
														message: "Required.",
														value: true,
													},
												})}
											/>
											<p className="text-danger mt-1">{_.get(errors,"title.message")}</p>
										</div>
									</Col>
								</Row>
								<Row className="mt-2">
									<Col xs="12" md="6">
										<div className="form-group">
											<label>PHONE NUMBER*</label>
											<InputMask 
												placeholder="XXX-XXX-XXXX"
												className="form-control"
												mask="999-999-9999"
												{...register("phone", {
													required: {
														message: "Required.",
														value: true,
													},
													pattern: {
														message: "Phone format invalid.",
														value: /^\d{3}-\d{3}-\d{4}$/,
													},
												})}
											/>
											<p className="text-danger mt-1">{_.get(errors,"phone.message")}</p>
										</div>
									</Col>
								</Row>
							</div>
							<Row>
								<Col>
									{
										RECAPTCHA_KEY &&
										<div className="table-center">
											<div>
												<Controller
													control={control}
													name="captcha"
													rules={{
														required: {
															message: "Robot verification failed.",
															value: true,
														},
													}}
													render={({ field: { onChange } }) => (
														<GoogleReCaptcha
															onVerify={onChange}
														/>
													)}
												/>
												<p className="text-danger mt-1">{_.get(errors,"captcha.message")}</p>
											</div>
										</div>
									}
								</Col>
							</Row>
							{
								generalError && (
									<Row>
										<Col>
											<div className="general-error">{generalError}</div>
										</Col>
									</Row>
								)
							}
							<Row>
								<Col xs="12">
									<div className="form-group">
										<div className="wrap-submit-button">
											<Button className="table-center" type="submit" disabled={loading}>
												{!loading ? 'ENTER NOW' : 'LOADING...'}
											</Button>
										</div>
									</div>
								</Col>
							</Row>
						</form>
					</div>
				</Container>
			</section>
		</Layout>
	);
};

export default Main;
