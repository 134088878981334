import React from "react";
import {
	BrowserRouter as Router,
	Switch,
	Route,
	Redirect,
} from "react-router-dom";

// 
import Main from "./pages/Main";

// Styles
import "./scss/App.scss";

function App() {
	return (
		<Router>
			<Switch>
				<Route path="/">
					<Main />
				</Route>
				<Route path="*">
					<Redirect to="/" />
				</Route>
			</Switch>
		</Router>
	);
}

export default App;
