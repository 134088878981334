import React from "react";

// Styles
import "./styles.scss";


const ModalSuccessMessage = (props) => {
	const { onClose } = props;
	
	return (
		<div id="success-modal">
			<div className="modal-content">
				<div>
					<button onClick={onClose} className="close-corner-button">X</button>
					<div>
						<h5>thank you for entering!</h5>
						<h5>we will be in contact shortly if you are one of the lucky winners</h5>
					</div>
				</div>
			</div>
			<div className="bgmodal-dark"></div>
		</div>
	);
};

export default ModalSuccessMessage;
