import React from 'react'

// Components
import Header from '../organisms/Header/index'
const Layout = ({ modalOpened, children }) => {
  
	const mainContainerClasses = [];
	if(modalOpened) {
		mainContainerClasses.push('modal-opened');
	}
  
  return (
    <div className={mainContainerClasses.join(' ')}>
        <Header />
        <React.Fragment>{children}</React.Fragment>
    </div>
  )
}

export default Layout