import React from "react";
import { Container } from "react-bootstrap";

// Styles
import "./styles.scss";

//Images
import Logo from "../../../assets/logo.png";
import LogoMobile from "../../../assets/logo_mobile.png";

const Header = () => {
	return (
		<header className="wrapper">
			<Container>
				<img className="propac-logo desktop" src={Logo} alt="propac + FRITO-LAY" />
				<img className="propac-logo mobile" src={LogoMobile} alt="propac + FRITO-LAY" />
			</Container>
		</header>
	);
};

export default Header;
